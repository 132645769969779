body {
  margin: 0;
  font-family: 'Space Grotesk', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(255,255,255);
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.opening div { padding-left: calc(50% + 5rem); padding-top: 24rem; padding-bottom: 10rem;}



.letItCenter { position: absolute; top: 0; bottom: 0; left: 0; right: 0; display: flex; flex-direction: column; padding: 10% 30%; }
.letItCenter img { max-width: 100%; }

div.half { max-width: 50%; }
svg.black * { fill: #000; }
svg.white * { fill: #fff; }


section.floodBlack { position: relative; background: black; color: white; z-index: 2; }
section.floodWhite { position: relative; background: white; color: black; z-index: 2; }
section.floodGrey { position: relative; background: #c4c4c4; color: black; z-index: 2; }

section.whiteForeground { position: relative; z-index: 2; color: white; }
section.blackForeground { position: relative; z-index: 2; color: black;
}

section.above { position: relative; z-index: 3; }

#update { opacity: 1; transition: 0.5s opacity ease-in; }
#update.fade { opacity: 0.6; }

section.floodImages { position: relative; display: flex; flex-direction: row; flex-wrap: wrap; z-index: 2; }
section.floodImages div.container { position: relative; width: 33.3333333%; overflow: hidden; aspect-ratio: 4/3; cursor: pointer; }
section.floodImages div.container div.img { position: absolute; top: 0; left: 0; right: 0; bottom: 0; z-index: 1; background-position: center; background-size: cover; background-repeat: no-repeat; width: 100%; }
section.floodImages div.container div.title { position: absolute; opacity: 0; top: 0; bottom: 0; left: 0; right: 0; background: linear-gradient(180deg, rgba(22,20,26,.67), rgba(22,20,26,.9) 50%); padding: 42px; z-index: 3; width: initial; transition: opacity 0.1s ease-out; }
section.floodImages div.container:hover div.title { opacity: 1; }

.spanAll { position: absolute; top: 0; left: 0; right: 0; bottom: 0; }
.spanAll div { padding-left: 34%; }
.padTopBig { padding-top: 120px; }
.padTopMedium { padding-top: 42px; }
.padTop { padding-top: 21px; }
.padAround { padding: 84px 5% 0; }
.padSides { padding: 0 5%; }
.padCap { padding-top: 84px; padding-bottom: 0; }
h3.padCap { margin-bottom: 0; }
section.twoColumn { display: flex; flex-direction: row; justify-content: space-between; }
section.twoColumn .founder { width: 40%; padding-left: 5%; padding-bottom: 11rem; }

img.imageFullWidth { max-width: 100%; }

div.logowall { display: flex; flex-wrap: wrap; justify-content: space-evenly; }
div.logowall img { max-width: 20%; }

section.hero { text-align: center; }
section.hero svg { width: 70%; }

section.threeColumn { display: flex; flex-direction: row; }
section.threeColumn div { width: 29%; }
section.threeColumn div.spantwo { width: 63%; padding-right: 3%; }
section.threeColumn.textBlocks { justify-content: space-between; }

section.contactus { text-align: center; padding-bottom: 11rem; }
section.contactus a svg { max-width: 18rem; max-height: 18rem; margin-top: 3rem; }
section.contactus div { text-align: right; left: 5%; width: 35%; }
section.contactus h3 { margin-bottom: 7px; }
section.contactus .lets { display: block; text-align: center; font-size: 1.5rem; color: black; margin-bottom: 1rem; }

ul.services { list-style-type: "↗︎¯ "; margin-top: 1.5rem; }
ul.services li::marker { color: rgba(255,255,255,.45); font-size: 1rem; }
ul.services li { color: #c4c4c4; margin-bottom: 0.5rem; }
p.hidden { display: none; }

p, ul.services li { font-size: 20px; line-height: 1.3; font-weight: 300; }
h2 { font-size: 48px; text-align: left; font-weight: 700; }
h3 { font-size: 48px; text-align: left; font-weight: 700; text-transform: uppercase; text-align: center; }
h4 { font-size: 36px; text-align: left; font-weight: 700; }
h4.scaleh4 { font-size: 2.2vw; }
h5 { font-size: 32px; text-align: left; font-weight: 700;  }
h6 { font-size: 32px; text-align: left; font-weight: normal;  }

nav { position: fixed; top: 0; height: 90px; background: black; left: 0; right: 0; z-index: 99; }
nav svg { position: absolute; right: 5%; width: 79px; top: -78px; }
nav ul { list-style-type: none; padding: 0 0 0 5%; display: flex; }
nav ul li { padding: 0 63px 0 0; color: white; font-weight: bold; font-size: 1rem; line-height: 60px; }
nav ul li a { color: white; text-decoration: none; }
nav ul li.extra { padding-left: 72px; }



.cssArrowHolder { position: fixed; padding: 0 !important; top: 175px; left: calc(50% - 65px); bottom: 0; z-index: 1; }
.cssArrow { position: absolute; top: 0; left: 50%; width: 100px; height: 100px; padding: 0 !important; border-left: solid 30px black; border-top: solid 30px black; background: transparent; border-right: none; border-bottom: none; transform: rotate(45deg); }
.cssArrowStem { padding: 0 !important; position: absolute; top: 0; bottom: 0; left: calc(50% + 50px); width: 30px; background: black; }


@media only screen and (min-width: 601px) and (max-width: 1080px) {
  section.founders div.diagonal { display: none; }
  section.founders div.founder h3, section.founders div.founder p { padding: 0 5%; }
  section.founders div.founder, section.founders div.founder:nth-of-type(2) { text-align: left !important; margin-bottom: initial; position: initial; width: 100%; display: flex; flex-direction: column; }
  section.founders div.founder, section.founders div.founder:nth-of-type(1) { position: initial; display: flex; flex-direction: column-reverse; }
  section.founders div.founder h3 { text-align: left; margin-bottom: initial; padding-top: 14px;}
  section.founders div.founder:nth-of-type(1) h3 { text-align: left; margin-bottom: initial; margin-top: 5rem;}
  section.founders div.founder:nth-of-type(2) h3 { margin-top: 0; }
  section.founderCap h3 { margin-block-start: 1em; margin-block-end: 1em; margin-inline-start: 0px; margin-inline-end: 0px; }
  section.founders { aspect-ratio: initial; padding-bottom: 2rem; }
  section.threeColumn.tabletShrink div { width: 11%; }
  section.threeColumn.tabletShrink div.spantwo { width: 78%; }
  section.hero { padding-left: 11%; }

  div.logowall img { max-width: 33%; }
}

@media only screen and (max-width: 884px) {
  nav ul li.clickchange { display: none; }
  section.contactus div { text-align: center; width: 100%; }
  section.contactus div h3 { text-align: center; }
  section.contactus { padding-bottom: 5rem; }
}

@media only screen and (max-width: 600px) {
  h3 { font-size: 32px; }
  h6, p { font-size: 20px; }

  .padTopMedium { padding-top: 3px; }
  section.threeColumn.textBlocks { flex-direction: column; }
  section.threeColumn.textBlocks div { width: 100%; }
  section.padAround, section.founders { padding: 14px 11%; }
  section.founders div.founder:nth-of-type(1) p, section.founders div.founder:nth-of-type(2) p, section.founders div.founder:nth-of-type(1) h3, section.founders div.founder:nth-of-type(2) h3 { padding: 0;}
  .letItCenter { padding: 20% 21px; }
  h4.scaleh4 { font-size: 2rem; }
  section.founders div.diagonal { display: none; }
  section.founders div.founder h3, section.founders div.founder p { padding: 0 5%; }
  section.founders div.founder, section.founders div.founder:nth-of-type(2) { text-align: left !important; margin-bottom: initial; position: initial; width: 100%; display: flex; flex-direction: column; }
  section.founders div.founder, section.founders div.founder:nth-of-type(1) { position: initial; display: flex; flex-direction: column-reverse; }
  section.founders div.founder h3 { text-align: left; margin-bottom: initial; padding-top: 14px;}
  section.founders div.founder:nth-of-type(1) h3 { text-align: left; margin-bottom: initial; margin-top: 1rem;}
  section.founders div.founder:nth-of-type(2) h3 { margin-top: 0; margin-top: 2rem; }
  section.founderCap h3 { margin-block-start: 1em; margin-block-end: 1em; margin-inline-start: 0px; margin-inline-end: 0px; }
  section.founders { aspect-ratio: initial; padding-bottom: 2rem; }
  section.threeColumn.tabletShrink div { width: 11%; }
  section.threeColumn.tabletShrink div.spantwo { width: 78%; }
  section.hero { padding-left: 11%; }
  section.hero div.padTopBig { text-align: left; }
  nav svg { right: 5%; }
  nav ul li { padding: 0 21px 0 0; }
  nav ul li.extra { padding-left: 5%; }
  div.logowall img { max-width: 50%; }
}



@media only screen and (max-width: 600px) {
  .opening div { padding-left: 33%; }
  .cssArrowHolder { left: 10%; }
  .cssArrow { position: absolute; top: 0; left: 50%; width: 50px; height: 50px; padding: 0 !important; border-left: solid 15px black; border-top: solid 15px black; background: transparent; border-right: none; border-bottom: none; transform: rotate(45deg); }
  .cssArrowStem { padding: 0 !important; position: absolute; top: 0; bottom: 0; left: calc(50% + 25px); width: 15px; background: black; }
  section.hero { padding-left: 0; }
  section.contactus { position: relative; z-index: 3; }
}
